import request from '@/utils/request'

export default {

  list(params) {
    return request({
      method: 'get',
      url: 'warehouse',
      params
    })
  },
  picking() {
    return request({
      method: 'get',
      url: 'warehouse/picking'
    })
  },
  putting() {
    return request({
      method: 'get',
      url: 'warehouse/putting'
    })
  },
  orderModify() {
    return request({
      method: 'get',
      url: 'warehouse/order-modify'
    })
  },
  stockWarning() {
    return request({
      method: 'get',
      url: 'warehouse/stock/warning'
    })
  }

}
