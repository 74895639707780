<template>
  <div>
    <my-nav-bar
      title="库存预警"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell
        v-for="(warehouse, index) in warehouses"
        :key="index"
        class="custom-cell"
        :title="warehouse.name"
        :value="warehouse.goodses_count ? warehouse.goodses_count + '种商品' : ''"
        is-link
        center
        @click="toDetailView(warehouse)"
      />
    </div>
  </div>
</template>

<script>
import warehouseApi from '@/api/warehouse'
import myNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'StockWarning',
  components: { myNavBar },
  data() {
    return {
      warehouses: null,
      showEmpty: false
    }
  },
  created() {
    this.getWarehouses()
  },
  methods: {
    getWarehouses() {
      this.beginLoad()
      warehouseApi.stockWarning().then(res => {
        this.endLoad()
        this.warehouses = res.data
        this.showEmpty = this.warehouses.length === 0
      })
    },
    toDetailView(warehouse) {
      this.$router.push({
        path: '/stock-warning-detail',
        query: {
          warehouse_name: warehouse.name,
          warehouse_id: warehouse.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .custom-cell {
    height: 50px;
  }
</style>
