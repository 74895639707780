var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "库存预警",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.warehouses, function (warehouse, index) {
    return _c('van-cell', {
      key: index,
      staticClass: "custom-cell",
      attrs: {
        "title": warehouse.name,
        "value": warehouse.goodses_count ? warehouse.goodses_count + '种商品' : '',
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(warehouse);
        }
      }
    });
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }